
import { defineComponent } from 'vue';
import PageHeader from '../components/PageHeader.vue'
import { getContact, getGameCompleted, ParsedItem } from '../lib/Contentful'

interface GameCompletedData {
  name: string;
  content: ParsedItem;
  contact: ParsedItem;
  location: string;
}

export default defineComponent({
  data(): GameCompletedData {
    return {
      name: null,
      content: null,
      contact: null,
      location: window.location.pathname.split("/")[1]
    }
  },
  name: 'GameCompleted',
  async mounted() {
    await this.getContent()
    const name = this.$route.params.name as string
    const bits = name.split("-").map(bit => {
      return bit[0].toUpperCase() + bit.substring(1);
    })
    this.name = bits.join(" ")
    await this.getContact(this.location)
    const w = window as any;
    w.fbq('track', 'ViewContent');
  },
  components: {
    PageHeader
  },
  methods: {
    async getContent() {
      this.content = (await getGameCompleted()).content
    },
    async getContact(location: string) {
      this.contact = await getContact(location)
    }
  }
});
